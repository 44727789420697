import 'whatwg-fetch';
import 'abortcontroller-polyfill';
// -----------------------------------------------------------------------------
import Rails from '@rails/ujs';
import merge from 'lodash/merge';
import qs from 'qs';
import FileSaver from 'file-saver';

// =============================================================================

let loadingIndicator;

function getLoadingIndicatorElement() {
  if (!loadingIndicator) {
    loadingIndicator = document.getElementById('loading-indicator');
  }

  return loadingIndicator;
}

export function startLoadingIndicator() {
  requestAnimationFrame(() => getLoadingIndicatorElement().classList.add('loading'));
}

export function stopLoadingIndicator() {
  requestAnimationFrame(() => getLoadingIndicatorElement().classList.remove('loading'));
}

// =============================================================================

/**
 *
 * @param {string} url
 * @param {string} [method]
 * @param {Object} [requestBody]
 * @returns {String}
 */
function parseUrl(url, method = 'GET', requestBody = null) {
  if (method === 'GET' && requestBody) {
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}${qs.stringify(requestBody, { arrayFormat: 'brackets' })}`;
  }

  return url;
}

/**
 *
 * @param {string} method
 * @param {Object} [requestBody]
 * @param {Object} [other]
 * @returns {Object}
 * @private
 */
function getRequestData(method = 'GET', requestBody = null, other = {}) {
  return merge(
    {
      method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'X-Requested-With': 'XMLHttpRequest',
        ...(!other?.headers) && { 'X-CSRF-Token': Rails.csrfToken() },
      },
      body: requestBody && method !== 'GET' ? JSON.stringify(requestBody) : null,
    },
    other,
  );
}

/**
 *
 * @param {number} status
 */
function handleHttpError(status) {
  let msg = I18n.t('js.errors.ajax.format');

  // if (status === 401) {
  //   // The users won't have time to read the msg anyway
  //   msg = I18n.t('devise.failure.unauthenticated');
  // }

  if (status === 404) {
    msg = I18n.t('js.errors.ajax.not_found');
  }

  if (status === 500) {
    msg = I18n.t('js.errors.ajax.internal_server');
  }

  notifier.add({ text: msg, type: 'error' });
}

/**
 * Set a minimum time for the requests so the loading animations have time to play out.
 * This improves the PERCEIVED performance.
 *
 * @returns {Promise}
 */
function minimumRequestTimer() {
  return new Promise((resolve) => {
    setTimeout(resolve, 350);
  });
}

// =============================================================================

/**
 *
 * @param {string} url
 * @param {Object} data
 * @returns {Promise<any>}
 */
async function request(url, data) {
  document.dispatchEvent(new CustomEvent('hsbcSsoRequest'));

  let response;

  try {
    response = await fetch(url, data);

    if (response.status === 401) {
      setTimeout(() => {
        // Reload will redirect the user to the login page
        // After login they will be redirected to the current page
        document.location.reload();
      }, 100); // let the script do its thing before reloading
    }

    return response;
  } catch (error) {
    if (!data?.signal?.aborted) {
      return handleHttpError(response?.status);
    }

    return response;
  }
}

// =============================================================================

/**
 *
 * @param data
 * @param response
 */
function handleResponseMsgs(data, response) {
  if (data?.errors?.length > 0) {
    const message = data.errors[0]?.title ?? data.errors[0]?.detail;
    notifier.add({ text: message, type: 'error' });
    return;
  }

  if (data?.errors?.base) {
    notifier.add({ text: data?.errors.base?.title, type: 'error' });
    return;
  }

  if (data?.meta?.message) {
    notifier.add({ text: data.meta.message, type: data?.meta?.messageType ?? 'success' });
    return;
  }

  if (response && !response.ok) {
    handleHttpError(response?.status);
  }
}

// =============================================================================

/**
 *
 * @param {string} url
 * @param {string} [method]
 * @param {Object} [requestBody]
 * @param {Object} [other]
 * @returns {Promise<any>}
 */
async function handleRequests(url, method = 'GET', requestBody, other) {
  if (!other?.hideLoadingEffect) {
    startLoadingIndicator();
  }

  const parsedUrl = parseUrl(url, method, requestBody);
  const requestData = getRequestData(method, requestBody, other);
  const minimumWaitTime = minimumRequestTimer();
  const response = request(parsedUrl, requestData);

  await Promise.all([response, minimumWaitTime]);

  stopLoadingIndicator();

  return response;
}

/**
 *
 * @param {string} url
 * @param {string} [method]
 * @param {Object} [requestBody]
 * @param {Object} [other]
 * @returns {Promise<any>}
 */
export const requestJson = async (url, method = 'GET', requestBody, other) => {
  const response = await handleRequests(url, method, requestBody, other);

  // Empty response
  if (response?.status === 204) {
    return {
      ok: response?.ok,
      status: 204,
    };
  }

  try {
    const json = await response?.json() ?? response ?? {};

    handleResponseMsgs(json, response);

    return json;
  } catch (error) {
    document.dispatchEvent(new CustomEvent('ca::error::notify', {
      detail: {
        error,
        params: {
          url,
          requestBody,
          response: {
            status: response?.status,
            redirected: response?.redirected,
            type: response?.headers?.get('Content-Type'),
            url: response?.url,
          },
        },
      },
    }));

    if (response && !response.ok) {
      handleHttpError(response?.status);
    }

    return { errors: [error] };
  }
};

/**
 *
 * @param {string} url
 * @param {Object} [requestBody]
 * @param {string} [method]
 * @returns {Promise<any>}
 */
export const requestFile = async (url, requestBody, method = 'POST') => {
  const response = await handleRequests(url, method, requestBody);

  if (response && !response.ok) {
    handleHttpError(response?.status);
  }

  response?.blob?.()?.then((blobData) => {
    const filename = response.headers.get('Content-Disposition')?.match(/filename="(.+)"/)[1];
    FileSaver.saveAs(blobData, filename);
  });
};

// =============================================================================

/**
 *
 * @param {AbortSignal} abortSignal
 * @param {string} url
 * @param {string} [method]
 * @param {Object} [requestBody]
 * @param {Object} [other]
 * @returns {Promise<any>}
 */
export const cancelableRequestJson = (abortSignal, url, method = 'GET', requestBody, other) => (
  requestJson(url, method, requestBody, {
    ...other,
    signal: abortSignal,
  })
);
