import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
// -----------------------------------------------------------------------------
import BrowserLinks from './browser-links';

// =============================================================================

export default function WarningModal({ dismissWarning }) {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = useCallback(() => setIsOpen((current) => !current), []);

  // ---------------------------------------------------------------------------

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-content-warning"
      onClosed={dismissWarning}
    >
      <ModalHeader toggle={toggle}>{I18n.t('js.browser.problem_header')}</ModalHeader>

      <ModalBody>
        <p>{I18n.t('js.browser.warning_content')}</p>

        <BrowserLinks />
      </ModalBody>

      <ModalFooter>
        <button type="button" className="btn btn-outline-secondary" onClick={toggle}>
          {I18n.t('ignore')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

WarningModal.propTypes = {
  dismissWarning: PropTypes.func.isRequired,
};
