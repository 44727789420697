import numbro from 'numbro';

// =============================================================================

const DefaultFormat = {
  thousandSeparated: true,
  mantissa: 2,
  optionalMantissa: true,
};

// =============================================================================

const NumericFormat = {
  ...DefaultFormat,
  negative: 'parenthesis',
};

/**
 *
 * @param {number} value
 * @param {Object} [format]
 * @returns {string}
 */
export const formatNumber = (value, format = {}) => numbro(Number(value) || 0).format({
  ...NumericFormat,
  ...format,
});

// TEMP UNTIL FIX ON BACKEND
/**
 *
 * @param {number} value
 * @param {Object} [format]
 * @returns {string}
 */
export const formatNumberRounded = (value, format = {}) => numbro(value ?? 0).format({
  ...NumericFormat,
  ...format,
  ...{ mantissa: 0 },
});

// =============================================================================

const PercentageFormat = {
  ...DefaultFormat,
  output: 'percent',
  spaceSeparated: true,
};

/**
 *
 * @param {number} value
 * @param {Object} format
 * @returns {string}
 */
export const formatPercentage = (value, format = {}) => numbro(value).format({
  ...PercentageFormat,
  ...format,
});

// =============================================================================

/**
 *
 * @param {number} value
 * @returns {string}
 */
export const formatFileSize = (value) => numbro(value ?? 0).format({
  output: 'byte',
  base: 'binary',
  mantissa: 2,
  spaceSeparated: true,
});

// =============================================================================

/**
 *
 * @param {number} value
 * @param {string} [currency]
 * @param {boolean} [rounded]
 * @returns {string}
 */
export const formatNumberWithCurrency = (value, currency = '', rounded = true) => {
  const isNeg = Math.sign(value) === -1;
  const format = rounded ? { mantissa: 0 } : undefined;

  return `${isNeg ? '(' : ''}${currency}${formatNumber(Math.abs(value), format)}${isNeg ? ')' : ''}`;
};

// =============================================================================

export function formatNumericLabels(value) {
  return numbro(value).format({
    average: true,
    mantissa: 1,
    optionalMantissa: true,
  });
}
