import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// -----------------------------------------------------------------------------
import BrowserLinks from './browser-links';

// =============================================================================

export default function ErrorModal() {
  useEffect(() => {
    // Disable login form
    document.querySelector('#new_user')?.setAttribute('disabled', 'disabled');
  }, []);

  // ---------------------------------------------------------------------------

  return (
    <Modal
      isOpen
      className="modal-content-danger"
    >
      <ModalHeader>{I18n.t('js.browser.problem_header')}</ModalHeader>

      <ModalBody>
        <p>{I18n.t('js.browser.error_content')}</p>

        <BrowserLinks />
      </ModalBody>
    </Modal>
  );
}
