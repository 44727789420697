import ReactDOM from 'react-dom';
import React from 'react';

// =============================================================================

/**
 *
 * @param {array} steps
 * @returns {array}
 */
function filterSteps(steps) {
  // Remove filterable steps that should not be displayed if no element exists in the DOM
  return (steps || []).filter(
    ({ required, selector }) => required || (selector && !!document.querySelector(selector)),
  );
}

/**
 *
 * @param {string} type
 */
async function showOnboarding(type) {
  const { default: Onboarding } = await import('../components/onboarding');
  const { default: steps } = await import(`../components/onboarding/configs/${type}`);

  const element = document.querySelector('#onboarding_tour_holder');

  if (!element || !steps) {
    return;
  }

  ReactDOM.render(<Onboarding key={type} steps={filterSteps(steps)} />, element);
}

// =============================================================================

document.addEventListener('click', (event) => {
  const target = event.target?.closest?.('[data-ca-walkthrough]');
  if (target) {
    event.preventDefault();
    showOnboarding(target.dataset.caWalkthrough);
  }
});
