// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
// =============================================================================
import jQuery from 'jquery';
import 'bootstrap';
// =============================================================================
import Rails from '@rails/ujs';
import './ajax-setup';
// =============================================================================
import I18n from './translations';
// =============================================================================
import Highcharts from './highcharts-config';
// =============================================================================
import './link-role-button';
import './scrollbar';
import './truncate';
import './ca-onboarding';
import './numeric-with-commas';
import './outdated-browser';

// =============================================================================

global.jQuery = jQuery;
global.$ = jQuery;

global.Highcharts = Highcharts;

global.I18n = I18n;

Rails.start();
