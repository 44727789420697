import React from 'react';
import PropTypes from 'prop-types';
// -----------------------------------------------------------------------------
import Icon from '../../components/icon';

// =============================================================================

function Link({
  name,
  href,
}) {
  return (
    <a href={href} target="_blank" rel="noreferrer noopener" className="d-inline-block p-2 w-25">
      <Icon icon={name} iconStyle="brands" className="fa-5x" />
      <br />
      <strong className="d-inline-block pt-2 text-capitalize">{name}</strong>
    </a>
  );
}

Link.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default function BrowserLinks() {
  return (
    <>
      <p>{I18n.t('js.browser.install_links')}</p>

      <div className="text-center pt-1">
        <Link name="firefox" href="https://www.mozilla.com/firefox/" />
        <Link name="chrome" href="https://www.google.com/chrome/" />
        <Link name="edge" href="https://www.microsoft.com/edge/" />
      </div>
    </>
  );
}
