import Rails from '@rails/ujs';
import jQuery from 'jquery';
// -----------------------------------------------------------------------------
import { startLoadingIndicator, stopLoadingIndicator } from './ca-request';

// =============================================================================

document.addEventListener('ajax:send', startLoadingIndicator);

document.addEventListener('ajax:complete', stopLoadingIndicator);

// -----------------------------------------------------------------------------

document.addEventListener('ajax:success', () => {
  // const xhr = event.detail[2];
  // if (xhr.responseURL?.includes('/users/login')) {
  //   // 401 error!
  //   document.location.reload();
  // } else {
  document.dispatchEvent(new CustomEvent('hsbcSsoRequest'));
  // }
});

// -----------------------------------------------------------------------------

document.addEventListener('ajax:error', (event) => {
  const xhr = event.detail[2];

  if (xhr.status === 401) {
    document.location.reload();
    return;
  }

  if (xhr.status === 0 && xhr.statusText === 'abort') {
    // Aborted request. Do nothing
    return;
  }

  let message = I18n.t('js.errors.ajax.general');

  if (xhr.status === 404) {
    message = I18n.t('js.errors.ajax.not_found');
  } else if (xhr.status === 500) {
    message = I18n.t('js.errors.ajax.internal_server');
  // } else if (exception === 'parsererror') {
  //   message = I18n.t('js.errors.ajax.format');
  // } else if (exception === 'timeout') {
  //   message = I18n.t('js.errors.ajax.timeout');
  }

  if (message) {
    notifier.add({
      text: message,
      type: 'error',
    });
  }
});

// =============================================================================
// Set up jQuery requests

jQuery.ajaxSetup({
  beforeSend(xhr) {
    xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());

    document.dispatchEvent(new CustomEvent('ajax:send', {
      detail: [xhr],
    }));
  },
  complete(xhr, status) {
    document.dispatchEvent(new CustomEvent('ajax:complete', {
      detail: [xhr, status],
    }));
  },
  success(data, status, xhr) {
    document.dispatchEvent(new CustomEvent('ajax:success', {
      detail: [data, status, xhr],
    }));
  },
  error(xhr, status, error) {
    document.dispatchEvent(new CustomEvent('ajax:error', {
      detail: [xhr, status, error],
    }));
  },
});

// Not sure if needed  :|
jQuery(document).ajaxError((event, jqXHR) => {
  if (jqXHR.status === 401) {
    document.location.reload();
  }
});

// =============================================================================

global.append_to_link_url = (className, key, value) => {
  const target = document.querySelector(`.${className}`);
  if (!target?.href) {
    return;
  }

  const url = new URL(target.href);
  const params = new URLSearchParams(url.search);

  params.delete(key);
  params.append(key, value);

  target.href = `${url.pathname}?${params.toString()}`;
};
