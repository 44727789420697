document.addEventListener('keydown', (event) => {
  if (event.key !== ' ') {
    return;
  }

  const target = event.target?.closest?.('a');
  if (target?.getAttribute('role') === 'button') {
    target.click();
    event.preventDefault();
  }
});
