import * as bootstrap from 'bootstrap';
// -----------------------------------------------------------------------------
import onDocumentReady from '../utils/on-document-ready';

// =============================================================================

function initializeTooltips() {
  const tooltipTriggerList = document.querySelectorAll('[data-toggle="tooltip"]');
  [...tooltipTriggerList].forEach((element) => new bootstrap.Tooltip(element));
}

// =============================================================================

onDocumentReady(initializeTooltips);

document.addEventListener(
  'initializeTooltips',
  (event) => initializeTooltips(event.detail?.wrapper),
  { passive: true }
);
