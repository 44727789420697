import jQuery from 'jquery';

// =============================================================================

const DEFAULTS = {
  time: 10000, // 10 seconds
  sticky: false,
  text: null,
  type: 'info',
  closeText: I18n.t('close'),
};

// -----------------------------------------------------------------------------

/**
 *
 * @param {Object} options
 * @returns {string}
 * @private
 */
function getNotificationId(options) {
  if (options.id) {
    return String(options.id);
  }

  return encodeURI(`${options.type || ''}__${options.text || ''}`);
}

/**
 *
 * {string} [text]
 * @private
 */
function getNotificationText(text) {
  if (typeof text === 'string') {
    return text;
  }

  if (typeof text === 'number') {
    return String(text);
  }

  if (Array.isArray(text)) {
    return text.join('<br>');
  }

  return '';
}

/**
 *
 * {string} [type]
 * @private
 */
function getNotificationType(type) {
  switch (type) {
    case 'success':
      return 'success';

    case 'alert':
    case 'warning':
      return 'warning';

    case 'error':
    case 'danger':
      return 'danger';

    case 'info':
    case 'notice':
    default:
      return 'info';
  }
}

/**
 *
 * @param {Object|string} [params]
 * @returns {Object}
 * @private
 */
function generateNotificationOptions(params = {}) {
  let options;

  if (typeof params === 'string') {
    options = { text: params };
  } else {
    options = { ...params };
  }

  options.text = getNotificationText(options.text);
  options.type = getNotificationType(options.type);
  options.id = getNotificationId(options);

  return options;
}

// -----------------------------------------------------------------------------

class Notification {
  /**
   *
   * @param {Object} [options]
   * @constructor
   */
  constructor(options) {
    this._options = {
      ...DEFAULTS,
      ...options,
    };

    this._timeout = null;
    this._create();
  }

  // Public
  // ---------------------------------------------------------------------------

  /**
   *
   */
  remove() {
    this.$element.removeClass('show');
    this.$element.slideUp('slow');
    if (this._options.onClose) {
      this._options.onClose();
    }
  }

  /**
   *
   */
  update(options = {}) {
    this._options = {
      ...this._options,
      ...options,
    };

    const $current = this.$element;

    $current.replaceWith(this._create());

    return this;
  }

  /**
   *
   */
  show() {
    this.$element
      .stop(true)
      .removeAttr('style')
      .addClass('show');

    this._setTimers();

    document.dispatchEvent(new CustomEvent('ca-utag-event', {
      detail: {
        event_category: 'content',
        event_action: this._options.type === 'danger' || this._options.type === 'error' ? 'error' : 'notification',
        event_content: `page_level: ${this._options.text}`,
      },
    }));
  }


  // Private
  // ---------------------------------------------------------------------------

  /**
   *
   * @returns {jQuery}
   * @private
   */
  _create() {
    this.$element = jQuery(`<div tabindex="0" class="alert alert-${this._options.type} fade" role="alert" aria-live="polite" aria-atomic="true">
<div>
  ${this._options.title ? `<strong>${this._options.title}</strong></br>` : ''}
  ${this._options.text ?? ''}
</div>
<button type="button" class="close" aria-hidden="true"><i class="far fa-times"></i></button>
</div>`);

    this._setEventHandlers();

    return this.$element;
  }

  /**
   *
   * @private
   */
  _clearTimers() {
    if (this._timeout !== null) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
  }

  /**
   *
   * @private
   */
  _setTimers() {
    this._clearTimers();

    if (!this._options.sticky) {
      this._timeout = setTimeout(() => this.remove(), this._options.time);
    }
  }

  // EVENT HANDLERS
  // ---------------------------------------------------------------------------

  /**
   *
   * @private
   */
  _setEventHandlers() {
    this.$element.on('click.notification', '.close', () => {
      this._clearTimers();
      this.remove();
    });

    this.$element.on('mouseenter.notification', () => {
      this._clearTimers();
      this.show();
    });

    this.$element.on('mouseleave.notification', () => {
      this._setTimers();
    });
  }
}

// -----------------------------------------------------------------------------

class Notifier {
  /**
   *
   * @constructor
   */
  constructor() {
    this._$container = null;
    this._notifications = {};
  }

  // Public
  // ---------------------------------------------------------------------------

  /**
   * Create new notification
   *
   * @returns {string} Notification id
   */
  add(params) {
    const options = generateNotificationOptions(params);
    let notification;

    if (this._notifications[options.id]) {
      notification = this._notifications[options.id];
      notification.update(options);
    } else {
      notification = this._createNotification(options);
    }

    // TODO: notification queue
    notification.show();

    return options.id;
  }

  /**
   * Remove notification by id
   *
   * @param {string} id
   */
  remove(id) {
    if (this._notifications[id]) {
      this._notifications[id].remove();
    }
  }

  /**
   * Remove all notifications
   *
   */
  removeAll() {
    Object.keys(this._notifications).forEach((id) => this.remove(id));
  }


  // Private
  // ---------------------------------------------------------------------------

  /**
   *
   * @param {Object} options
   * @returns {Notification}
   * @private
   */
  _createNotification(options) {
    const notification = new Notification(options);

    this._notifications[options.id] = notification;

    if (!this._$container) {
      this._$container = jQuery('#alerts_holder');
    }

    this._$container.append(notification.$element);

    return notification;
  }
}

global.notifier = new Notifier();
