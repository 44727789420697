import { Notifier } from '@airbrake/browser';

// =============================================================================

if (CA_CONFIG?.airbrake_project_key) {
  const airbrake = new Notifier({
    projectId: CA_CONFIG.airbrake_project_id,
    projectKey: CA_CONFIG.airbrake_project_key,
    environment: CA_CONFIG.airbrake_env,
  });

  airbrake.addFilter((notice) => {
    notice.context.userContext = CA_CONFIG.airbrake_context ?? {};

    return notice;
  });

  // ---------------------------------------------------------------------------

  // Send error to Airbrake
  document.addEventListener('ca::error::notify', (event) => {
    const { error, params } = (event?.detail ?? {});
    airbrake.notify({ error, params });
  }, { passive: true });

  // ---------------------------------------------------------------------------

  global.airbrake = airbrake;
}
