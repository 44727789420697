import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// =============================================================================

export const COMMON_ICON_CLASSNAME = 'ca-icon';

// =============================================================================

const Icon = forwardRef((
  {
    icon,
    iconStyle,
    className,
  },
  ref,
) => (
  <i
    ref={ref}
    className={`${COMMON_ICON_CLASSNAME} fa-${iconStyle} fa-${icon} ${className}`}
    aria-hidden
  />
));

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.oneOf(['regular', 'light', 'solid', 'duotone', 'brands']),
  className: PropTypes.string,
};

Icon.defaultProps = {
  iconStyle: 'regular',
  className: '',
};

export default Icon;

// =============================================================================

export const IconWarning = forwardRef(({ className }, ref) => (
  <Icon
    ref={ref}
    icon="exclamation-triangle"
    iconStyle="solid"
    className={`text-warning ${className}`}
  />
));

IconWarning.propTypes = {
  className: PropTypes.string,
};

IconWarning.defaultProps = {
  className: '',
};
