export default function onDocumentReady(action) {
  if (document.readyState === 'loading') {
    document.addEventListener(
      'DOMContentLoaded',
      () => action(),
      { once: true, passive: true }
    );
  } else { // `DOMContentLoaded` already fired
    action();
  }
}
