import ReactDOM from 'react-dom';
import React from 'react';
// -----------------------------------------------------------------------------
import onDocumentReady from '../utils/on-document-ready';
import InputNumber from '../components/main/form-fields/input-number';

// =============================================================================

const Selector = '.numeric-with-commas';
const ContainerClassName = 'number-format-container';

// =============================================================================

/**
 *
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
function getContainer(element) {
  if (element.nextElementSibling?.classList?.contains(ContainerClassName)) {
    return element.nextElementSibling;
  }

  const container = document.createElement('div');
  container.className = ContainerClassName;
  element.parentElement.insertBefore(container, element.nextSibling);

  return container;
}

/**
 *
 * @param {HTMLElement} element
 */
function setNumberMask(element) {
  if (!element) {
    return;
  }

  ReactDOM.render(
    <InputNumber
      value={element.value}
      onChange={(value) => {
        element.value = value ?? '';
        element.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      }}
      // -------------------------------
      readOnly={element.readOnly}
      disabled={element.disabled}
      required={element.required}
    />,
    getContainer(element)
  );

  element.classList.add('d-none');
}

// =============================================================================

function initializeNumberMask() {
  Array.prototype.forEach.call(
    document.querySelectorAll(Selector),
    (element) => setNumberMask(element),
  );
}

onDocumentReady(initializeNumberMask);

document.addEventListener(
  'initializeNumberMask',
  initializeNumberMask,
  { passive: true }
);
