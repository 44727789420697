import jQuery from 'jquery';

// =============================================================================

jQuery(document).on('mouseenter', '.truncate', (event) => {
  const target = event.target?.closest?.('.truncate');
  if (
    target
    && !target.title
    && target?.offsetWidth < target.scrollWidth
  ) {
    target.title = target.innerText;
  }
});
