import Highcharts from 'highcharts';
// -----------------------------------------------------------------------------
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsModuleAccessibility from 'highcharts/modules/accessibility';
import HighchartsModuleExportData from 'highcharts/modules/export-data';
import HighchartsModuleExporting from 'highcharts/modules/exporting';
import HighchartsModuleOfflineExporting from 'highcharts/modules/offline-exporting';
// import HighchartsModuleNoData from 'highcharts/modules/no-data-to-display';
import HighchartsModuleSolidGauge from 'highcharts/modules/solid-gauge';
// -----------------------------------------------------------------------------
import { formatNumericLabels } from '../utils/format-number';

// =============================================================================

HighchartsMore(Highcharts);
HighchartsModuleAccessibility(Highcharts);
HighchartsModuleExportData(Highcharts);
HighchartsModuleExporting(Highcharts);
HighchartsModuleOfflineExporting(Highcharts);
// HighchartsModuleNoData(Highcharts);
HighchartsModuleSolidGauge(Highcharts);

// =============================================================================

export const HighchartsHeight = 500;

const HighchartsDefaultLabels = {
  padding: 2,
  style: {
    // textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: '500',
  },
};

const HighchartsColours = [
  '#3EA0CD',
  '#F28F43',
  '#80699B',
  '#8BBC21',
  '#910000',
  '#1AADCE',
  '#492970',
  '#434348',
  '#77A1E5',
];

// =============================================================================

function formatNumber(number = 0) {
  return Number(number).toLocaleString('en', {
    maximumFractionDigits: 2,
  });
}

function labelFormatter() {
  return formatNumericLabels(this.value);
}

// =============================================================================

const HighchartsDefaultConfig = {
  chart: {
    height: HighchartsHeight,
    spacing: [8, 8, 8, 8],
    events: {
      render: (event) => {
        const { renderer, seriesGroup } = event.target;
        if (renderer && renderer.box) {
          renderer.box.setAttribute('aria-hidden', 'true');
          seriesGroup.element.setAttribute('aria-hidden', 'true');
        }
      },
    },
  },

  colors: HighchartsColours,

  title: {
    align: 'left',
    text: '',
    style: {
      // color: '#1D354D,
      fontSize: '14px',
      lineHeight: '1.5em',
      textTransform: 'uppercase',
      fontWeight: '700',
    },
  },

  credits: {
    enabled: false,
  },

  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    itemStyle: {
      color: '#333',
      fontSize: '12px',
      fontWeight: '300',
    },
    itemHiddenStyle: {
      color: '#666',
    },
  },

  plotOptions: {
    // series: {
    //   marker: {
    //     enabled: true,
    //     symbol: 'circle',
    //     radius: 5,
    //     fillColor: null,
    //     lineWidth: 2.5,
    //     lineColor: '#FFFFFF'
    //   },
    //   lineWidth: 2.5,
    //   linecap: 'round',
    // }

    area: {
      marker: {
        fillColor: '#FFFFFF',
        symbol: 'circle',
        lineColor: null,
        lineWidth: 2,
        radius: 3,
        enabledThreshold: 1,
      },
    },

    // bar: {},

    line: {
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 5,
        fillColor: null,
        lineWidth: 2.5,
        lineColor: '#FFFFFF',
      },
      lineWidth: 2.5,
      linecap: 'round',
    },

    pie: {
      borderColor: '#ffffff',
      innerSize: '90%',
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        // style: {
        //   color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
        // },
      },
    },
  },

  tooltip: {
    formatter() {
      return `${this.x ?? this.point?.name}: <b>${formatNumber(this.y)}</b>`;
    },
  },

  xAxis: {
    labels: HighchartsDefaultLabels,
  },

  yAxis: {
    title: {
      style: {
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: '14px',
        fontWeight: '600',
      },
    },
    labels: {
      ...HighchartsDefaultLabels,
      formatter: labelFormatter,
    },
    gridLineWidth: 1.5,
    gridLineColor: '#DEDEDE',
    plotLines: [{
      color: '#DEDEDE',
      width: 1,
    }],
    plotBands: [{
      from: -1000000000,
      to: 0,
      color: '#fefafa',
      gridZIndex: -2,
      zIndex: -2,
    }],
  },

  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
    allowHTML: false,
    fallbackToExportServer: false,
  },
};

Highcharts.setOptions(HighchartsDefaultConfig);

// =============================================================================

export default Highcharts;
