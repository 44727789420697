import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

// =============================================================================

function initScrolling() {
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-simplebar]'),
    (element) => {
      const simpleBar = SimpleBar.instances.get(element);

      if (simpleBar) {
        simpleBar.unMount();
      }

      new SimpleBar(element);
    },
  );
}

// -----------------------------------------------------------------------------

document.addEventListener('initScrolling', initScrolling, { passive: true });
