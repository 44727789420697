import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

// =============================================================================

const InputNumber = forwardRef(({
  value,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  required,
  readOnly,
  disabled,
  invalid,
  allowNegative,
  decimalScale,
}, ref) => {
  return (
    <NumericFormat
      ref={ref}
      value={value ?? ''}
      onValueChange={(response) => onChange(response.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      // -------------------------------
      className={`form-control ${readOnly ? 'readonly' : ''} ${disabled ? 'disabled' : ''} ${invalid ? 'is-invalid' : ''}`}
      thousandSeparator
      // -------------------------------
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      allowNegative={allowNegative}
      decimalScale={decimalScale}
    />
  );
});

InputNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
};

InputNumber.defaultProps = {
  value: undefined,
  onFocus: undefined,
  onBlur: undefined,
  required: false,
  readOnly: false,
  disabled: false,
  invalid: false,
  allowNegative: undefined,
  decimalScale: undefined,
};

export default InputNumber;
