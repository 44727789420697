import ReactDOM from 'react-dom';
import React from 'react';
// -----------------------------------------------------------------------------
import onDocumentReady from '../../utils/on-document-ready';
import ErrorModal from './error-modal';
import WarningModal from './warning-modal';

// =============================================================================

const StorageKey = 'outdated-browser-last-dismissed';

function dismissWarning() {
  sessionStorage.setItem(StorageKey, Date.now());
}

function isWarningDismissed() {
  const lastDismissed = sessionStorage.getItem(StorageKey);

  return (
    // Ignore warnings for HSBC
    process.env.RAILS_ENV.indexOf('hsbc_') !== -1
    // Last dismissed less than 20 hours ago
    || (lastDismissed && (Date.now() - lastDismissed < (20 * 60 * 60 * 1000)))
  );
}

// =============================================================================

function isIEOrOldEdge() {
  const div = document.createElement('div');
  if (!('borderImage' in div.style)) {
    return true; // IE 10 or lower
  }

  try {
    document.querySelector(':focus-within');
  } catch (error) {
    return true;
  }

  return false;
}

// -----------------------------------------------------------------------------

let container;
function getContainer() {
  if (!container) {
    container = document.createElement('div');
    document.body.appendChild(container);
  }

  return container;
}

// =============================================================================

/**
 * is the current browser not supported?
 *
 * @returns {boolean}
 */
function showError() {
  if (isIEOrOldEdge()) {
    return true;
  }

  // Older than Firefox 59 & Chrome 63 (2018)
  return !CSS.supports('overscroll-behavior', 'auto');
}

/**
 * is the browser outdated?
 *
 * @returns {boolean}
 */
function showWarning() {
  if (isWarningDismissed()) {
    return false;
  }

  // Older than Firefox 80 (Aug 2020) & Chrome 84 (Jul 2020)
  return !CSS.supports('appearance', 'none');
}

// =============================================================================

function outdatedBrowser() {
  if (showError()) {
    ReactDOM.render(
      <ErrorModal />,
      getContainer(),
    );
  } else if (showWarning()) {
    ReactDOM.render(
      <WarningModal dismissWarning={dismissWarning} />,
      getContainer(),
    );
  }
}

// =============================================================================

onDocumentReady(outdatedBrowser);
