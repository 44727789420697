import I18n from 'i18n-js';

// =============================================================================

I18n.defaultLocale = global.I18nConfig?.defaultLocale ?? 'en';
I18n.locale = global.I18nConfig?.locale ?? 'en';
I18n.translations = global.I18nConfig?.translations ?? {};

// =============================================================================

export default I18n;
