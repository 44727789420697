/*!
 * Unmask Password field
 *
 */

/**
 *
 * @param {HTMLInputElement} input
 * @param {HTMLElement} switchNode
 * @private
 */
function mask(input, switchNode) {
  input.setAttribute('type', 'password');
  switchNode.innerText = I18n.t('show');
}

/**
 *
 * @param {HTMLInputElement} input
 * @param {HTMLElement} switchNode
 * @private
 */
function unmask(input, switchNode) {
  input.setAttribute('type', 'text');
  switchNode.innerText = I18n.t('hide');
}

/**
 *
 * @param {HTMLInputElement} input
 * @param {HTMLElement} switchNode
 * @private
 */
function toggle(input, switchNode) {
  if (input.getAttribute('type') === 'password') {
    unmask(input, switchNode);
  } else {
    mask(input, switchNode);
  }
}

// -----------------------------------------------------------------------------

/**
 *
 * @param {HTMLElement} switchNode
 */
function logonPasswordUnmask(switchNode) {
  const formGroup = switchNode.closest('.form-group');
  let input;

  if (formGroup) {
    input = formGroup.querySelector('input');
  }

  if (input) {
    toggle(input, switchNode);
  }
}

// -----------------------------------------------------------------------------

document.addEventListener('click', (event) => {
  if (event.target && event.target.dataset && event.target.dataset.toggle === 'password-unmask') {
    event.preventDefault();
    logonPasswordUnmask(event.target);
  }
});
